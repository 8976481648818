import React, { useEffect, useState, useRef, useCallback } from 'react';
import GoogleMapReact from 'google-map-react';
import BlockUi from 'react-block-ui';
import Api from '../../api/global.service';
import CardContainer from '../../components/card/card-container.jsx';
import env from '../../environment.js';
import CardTitleParcela from '../../components/card/card-title-parcela.jsx';
import useCroquisHook from '../../hooks/useCroquisHook.jsx';
import NorthIcon from '../../components/map/north-icon.jsx';
import FormSelectMaterial from '../../nuup/componentes/Forms/FormSelectMaterial.jsx';
import TerrainDirection from '../../components/map/terrain-direction.jsx';
import MapText from '../../components/card/map-text.jsx';
import CardBasic from '../../components/card/card-basic.jsx';
import MarkerInfoWindow from '../../components/map/marker-infowindow.jsx';
import LabelRiskEudr from '../../components/label/label-risk-eudr.jsx';
import generalCatchErrorsModal from '../../components/modal/modal-error.jsx';
import EudrSicAccordion from '../../components/accordions/eudr-sic-accordion.jsx';
import EudrRisk from './eudr-risk.js';
import LabelAlertas from '../../components/label/label-alertas.jsx';
import LabelWhisp from '../../components/label/label-whisp.jsx';
import LabelAreaNatural from '../../components/label/label-area-natural.jsx';
import IDs_WHISP from '../../constants/IdsWhisp.js';
import { useReactToPrint } from 'react-to-print';
import LabelRecomendaciones from '../../components/label/label-recomendaciones.jsx';

const MiddleRiskDefault = {
  id: IDs_WHISP.MEDIO,
  color: '#FFDDB0',
  nombre: 'Medio',
};

const ParcelasEUDR = ({ uuid, parcela, parcelaData, centroid, blocking, width }) => {
  const componentRef = useRef(null);
  const topHeight = 130;
  const [mapType, setMapType] = useState('satellite');
  const [userEudrRisk, setUserEudrRisk] = useState({});
  const [areasNaturales, setAreasNaturales] = useState([]);
  const [alertasPoligono, setAlertasPoligono] = useState([]);
  const [eudrRisk, setEudrRisk] = useState({});
  const [whispRisk, setWhispRisk] = useState({});
  const [blockingEudrRisk, setBlockingEudrRisk] = useState(true);
  const [isPrinting, setIsPrinting] = useState(false);
  const { areaGMaps, renderMap, center, angle, pointsOnMap, lengths, zoom } = useCroquisHook(
    parcelaData,
    userEudrRisk?.cat_riesgo_eudr?.color || eudrRisk?.cat_riesgo_eudr?.color
  );

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `ReporteEUDR_${parcela.nombre}_${parcela.cuenta}`,
    copyStyles: true,
    pageStyle: `
      @page {
        size: landscape;
      }
      @media print {
        .pagebreak {
          clear: both;
          page-break-after: always !important;
        }
        .no-print {
          display: none !important;
        }
      }
    `,
  });

  useEffect(() => {
    if (isPrinting) {
      setTimeout(() => {
        handlePrint();
        setIsPrinting(false);
      }, 300);
    }
  }, [isPrinting]);

  const getEudrRisk = data => {
    const filteredAlerts = data.alertasPoligono.filter(alert => alert.error != 1);
    if (data.whispRisk && data.whispRisk.id === IDs_WHISP.ALTO) {
      return { cat_riesgo_eudr: data.whispRisk };
    }
    if (data.whispRisk && data.whispRisk.id === IDs_WHISP.BAJO && filteredAlerts.length === 0 && data.areasNaturales.length === 0) {
      return { cat_riesgo_eudr: data.whispRisk };
    }
    return { cat_riesgo_eudr: MiddleRiskDefault };
  };

  useEffect(() => {
    if (uuid != null) {
      Api.get('parcela_eudr_risk', uuid)
        .then(response => {
          setEudrRisk(getEudrRisk(response.data));
          setUserEudrRisk(response.data.userRiesgoEudr);
          setAreasNaturales(response.data.areasNaturales);
          setAlertasPoligono(response.data.alertasPoligono);
          setWhispRisk(response.data.whispRisk);
          setBlockingEudrRisk(false);
        })
        .catch(e => {
          generalCatchErrorsModal(e.response?.status, '/admin/parcelas/list');
          setBlockingEudrRisk(false);
        });
    }
  }, []);

  return (
    <>
      <div
        style={{
          position: 'relative',
          marginTop: 46,
        }}>
        <div
          className="mb-5 no-print"
          style={{
            position: 'absolute',
            top: -52,
            right: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '60%',
          }}>
          <FormSelectMaterial
            id="tipoMapa"
            error={false}
            label="Tipo Mapa"
            value={mapType}
            onChange={v => setMapType(v)}
            height={34}
            options={[
              { value: 'satellite', label: 'Satelital' },
              { value: 'terrain', label: 'Terreno' },
            ]}
            showAll={false}
          />
          <button onClick={() => setIsPrinting(true)} className="btn btn-outline-secondary" type="button">
            <i className="fa fa-download" aria-hidden="true"></i> Descargar Reporte
          </button>
        </div>

        <div ref={componentRef}>
          <div className="row pb-4">
            <div className={isPrinting ? 'col-5 mb-2' : 'col-lg-5 mb-2'}>
              <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
                <CardContainer height={topHeight}>
                  <div className="row">
                    <CardTitleParcela
                      cuenta_uuid={parcela.cuenta_uuid}
                      cuenta_name={parcela.cuenta}
                      parcela_name={parcela.nombre}
                      localidad={parcela.localidad}
                      municipio={parcela.municipio}
                      certifications={parcela.certifications}
                    />
                  </div>
                </CardContainer>
              </BlockUi>
            </div>
            <div className={isPrinting ? 'col-4 mb-2' : 'col-lg-4 mb-2'}>
              <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
                <CardContainer height={topHeight} style={{ paddingLeft: 10, paddingRight: 10 }}>
                  <div className="row">
                    <div className="col-2">
                      <img src={env.images.icon.area} style={{ height: '55px' }} />
                    </div>
                    <div className="col-5 pl-3">
                      <h4 className="font-lato-normal-400">
                        {(areaGMaps || 0).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                        has
                      </h4>
                      <p className="font-lato-normal-500 pb-3" style={{ color: env.colors.neutral_700 }}>
                        calculadas (automático)
                      </p>
                    </div>
                    <div className="col-5">
                      <h4 className="font-lato-normal-400">
                        {(parcela.superficie || 0).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                        has
                      </h4>
                      <p className="font-lato-normal-500 pb-3" style={{ color: 'rgb(91, 91, 91)' }}>
                        reportadas del productor/a
                      </p>
                    </div>
                  </div>
                </CardContainer>
              </BlockUi>
            </div>
            <div className={isPrinting ? 'col-3 mb-2' : 'col-lg-3 mb-2'}>
              <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
                <CardContainer height={topHeight}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      height: '100%',
                      justifyContent: 'space-between',
                    }}>
                    <h6>El riesgo de esta parcela es:</h6>
                    <LabelRiskEudr eudrRisk={userEudrRisk || eudrRisk} />
                  </div>
                </CardContainer>
              </BlockUi>
            </div>
          </div>
          <CardContainer style={{ padding: 6, marginBottom: 16 }}>
            <div className="row">
              <div className="col-6">
                <div className="map-rounded" style={{ minHeight: '300px', height: '100%', width: '100%' }}>
                  <NorthIcon color={mapType === 'satellite' ? 'white' : 'black'} />

                  {parcela.poligono ? (
                    <>
                      <TerrainDirection angle={angle} color={mapType === 'satellite' ? 'white' : 'black'} />
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: env.googleApiKey,
                          libraries: ['geometry', 'elevation'],
                        }}
                        options={() => ({ mapTypeId: mapType })}
                        center={center}
                        zoom={zoom}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => renderMap(map, maps)}>
                        {lengths.map((it, i) => {
                          return (
                            <MapText
                              lat={it?.lat}
                              lng={it?.lng}
                              key={i}
                              text={it?.length + 'm'}
                              style={{ color: mapType === 'satellite' ? 'white' : 'black' }}
                            />
                          );
                        })}
                        {pointsOnMap.map((it, i) => (
                          <MapText
                            lat={it?.lat}
                            lng={it?.lng}
                            key={i}
                            text={it?.title}
                            style={{ color: mapType === 'satellite' ? 'white' : 'black' }}
                          />
                        ))}
                      </GoogleMapReact>
                    </>
                  ) : null}
                  {parcela.poligono === null &&
                    parcelaData?.centroid &&
                    !isNaN(parcelaData?.centroid.lat) &&
                    !isNaN(parcelaData?.centroid.lng) && (
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: env.googleApiKey, libraries: ['elevation'] }}
                        options={() => ({ mapTypeId: mapType })}
                        center={centroid}
                        zoom={zoom}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => renderMap(map, maps)}>
                        <MarkerInfoWindow
                          key={1}
                          selectedElement={1}
                          lat={parcelaData?.centroid?.lat}
                          lng={parcelaData?.centroid?.lng}
                          productor={'Polígono pendiente de registrar'}
                          color={'#91B833'}
                        />
                      </GoogleMapReact>
                    )}
                  {parcela.centroide === null && parcela.poligono === null && (
                    <CardBasic
                      height={290}
                      label="Ubicación no disponible"
                      value={''}
                      style={{ backgroundColor: env.colors.neutral_1 }}></CardBasic>
                  )}
                </div>
              </div>
              <div className="col-6" style={{ padding: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                  <span style={{ fontSize: '14px' }}>Resultado preliminar del nivel de riesgo EUDR:</span>
                  <div style={{ display: 'flex', padding: '10px 0 10px 0' }}>
                    <LabelRiskEudr eudrRisk={eudrRisk} />
                  </div>
                </div>
                {parcelaData && parcelaData.parcelaPolygon !== null && !blocking ? (
                  <>
                    <LabelAlertas alertas={alertasPoligono} blocking={blockingEudrRisk} />
                    <LabelWhisp whisp={whispRisk} blocking={blockingEudrRisk} />
                    <LabelAreaNatural areasNaturales={areasNaturales} blocking={blockingEudrRisk} />
                    <LabelRecomendaciones
                      whisp={whispRisk}
                      alertas={alertasPoligono}
                      areasNaturales={areasNaturales}
                      blocking={blockingEudrRisk}
                    />
                    <span style={{ fontSize: '10px' }}>
                      * Análisis realizado con la tecnología Whisp en base a mapas satelitales validados por fuentes oficiales.{' '}
                      <a style={{ color: '#789D16' }} href={'https://openforis.org/solutions/whisp/'} target="_blank" rel="noreferrer">
                        Saber más ->
                      </a>
                    </span>
                  </>
                ) : (
                  <span style={{ fontSize: '14px' }}>Es necesario un polígono de la parcela para poder hacer análisis</span>
                )}
              </div>
            </div>
          </CardContainer>
          <CardContainer>
            <div className="row">
              <div className={isPrinting ? 'col-6' : 'col-lg-6'}>
                <EudrSicAccordion uuid={uuid} isPrinting={isPrinting} />
              </div>
              <div
                className={isPrinting ? 'col-6' : 'col-lg-6'}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingTop: 14 }}>
                <EudrRisk uuid={uuid} eudrRisk={userEudrRisk} loadingEudrData={blockingEudrRisk} />
              </div>
            </div>
          </CardContainer>
          <div style={{ padding: 20 }}>
            <p style={{ fontSize: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Descargo de responsabilidad legal:</span> Los materiales proporcionados en este sitio se
              ofrecen "tal cual", sin garantías expresas o implícitas de ningún tipo, incluyendo, pero no limitándose a, garantías de
              comerciabilidad o idoneidad para un propósito particular y no infracción.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParcelasEUDR;
