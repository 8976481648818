import React from 'react';
import env from '../../environment';

const LabelAreaNatural = ({ areasNaturales, blocking }) => {
  if (blocking) return null;
  if (areasNaturales.length === 0) {
    return (
      <div style={{ padding: 10 }}>
        <i
          className="fa fa-check"
          style={{
            color: '#789D16',
            marginRight: 10,
          }}
        />
        <span>
          Áreas Naturales Protegidas: <span style={{ fontWeight: 'bold' }}>No se encuentra en un área natural protegida</span>
        </span>
      </div>
    );
  }
  return (
    <div style={{ padding: 10 }}>
      <i
        className="fa fa-exclamation-triangle"
        aria-hidden="true"
        style={{
          color: env.colors.secondary_300,
          marginRight: 10,
        }}
      />
      <span>
        Áreas Naturales Protegidas: <span style={{ fontWeight: 'bold' }}>Esta parcela se encuentra en un área protegida: </span>
      </span>
      {areasNaturales.map((area, index) => (
        <span key={area.id} style={{ fontWeight: 'italic' }}>
          {area.url ? (
            <a style={{ color: '#789D16' }} href={area.url} target="_blank" rel="noreferrer">
              {area.nombre}
            </a>
          ) : (
            area.nombre
          )}
          {index < areasNaturales.length - 1 ? ', ' : ''}
        </span>
      ))}
    </div>
  );
};

export default LabelAreaNatural;
