import React from 'react';
import env from '../../environment';

const LabelAlertas = ({ alertas, blocking }) => {
  if (blocking) return null;
  const filteredAlerts = alertas.filter(alert => alert.error == 1);
  if (filteredAlerts.length === 0) {
    return (
      <div style={{ padding: 10 }}>
        <i
          className="fa fa-check"
          style={{
            color: '#789D16',
            marginRight: 10,
          }}
        />
        <span>
          Integridad de datos: <span style={{ fontWeight: 'bold' }}>Polígono válido, no se detectaron alertas de calidad de datos</span>
        </span>
      </div>
    );
  }
  return (
    <div style={{ padding: 10 }}>
      <i
        className="fa fa-exclamation-triangle"
        aria-hidden="true"
        style={{
          color: env.colors.secondary_300,
          marginRight: 10,
        }}
      />
      <span>
        Integridad de datos:{' '}
        <span style={{ fontWeight: 'bold' }}>
          existen alertas de calidad de datos:{' '}
          {filteredAlerts.map((alert, index) => (
            <span key={alert.id} style={{ fontWeight: 'italic' }}>
              {alert.titulo}
              {index < filteredAlerts.length - 1 ? ', ' : ''}
            </span>
          ))}
        </span>
      </span>
    </div>
  );
};

export default LabelAlertas;
