import React from 'react';
import IDs_WHISP from '../../constants/IdsWhisp';
import IconAlert from '../icon/icon-alert';
import IconCheck from '../icon/icon-check';
import IconWrong from '../icon/icon-wrong';

const LabelWhisp = ({ whisp, blocking }) => {
  if (blocking) return null;
  let icon = <IconAlert />;
  if (whisp && whisp.id === IDs_WHISP.BAJO) {
    icon = <IconCheck />;
  }

  if (whisp && whisp.id === IDs_WHISP.ALTO) {
    icon = <IconWrong />;
  }

  return (
    <div style={{ padding: 10 }}>
      {icon}
      <span>
        Riesgo de deforestación*: <span style={{ fontWeight: 'bold' }}>{whisp?.nombre ?? 'Se necesita más información.'} </span>
      </span>
    </div>
  );
};

export default LabelWhisp;
