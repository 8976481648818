import React from 'react';
import IDs_WHISP from '../../constants/IdsWhisp';
import env from '../../environment';

const RECOMENDACIONES = {
  POLIGONO:
    'Revisa el polígono en el Perfil General. Según la gravedad del error, puedes editar algunos de sus puntos para ajustarlo o vuelve a georreferenciar el polígono en campo.',
  DEFORESTACION_MEDIO:
    'No se detectaron cambios en la cubierta forestal ni antes ni después de 2020. Existe un riesgo de que en un futuro se active alguna alerta de deforestación. Revisa el estatus del productor y adjunta información adicional para comprobar que en esta parcela se cultiva desde antes del 2020.',
  DEFORESTACION_ALTO:
    'Se detectó una alerta de deforestación después del 2020. Revisa el estatus del productor y adjunta información adicional para comprobar que en esta parcela se cultiva desde antes del 2020.',
  AREAS_NATURALES: 'Revisa que esta parcela cumple con las prácticas especificadas en el Plan de Manejo del Área Natural Protegida.',
};

const LabelRecomendaciones = ({ whisp, alertas, areasNaturales, blocking }) => {
  if (blocking) return null;
  const recomendaciones = [];
  const filteredAlerts = alertas.filter(alert => alert.error == 1);
  if (filteredAlerts.length > 0) {
    recomendaciones.push(RECOMENDACIONES.POLIGONO);
  }
  if (areasNaturales.length > 0) {
    recomendaciones.push(RECOMENDACIONES.AREAS_NATURALES);
  }
  if (whisp && whisp.id === IDs_WHISP.ALTO) {
    recomendaciones.push(RECOMENDACIONES.DEFORESTACION_ALTO);
  } else if (!whisp || (whisp && whisp.id !== IDs_WHISP.BAJO)) {
    recomendaciones.push(RECOMENDACIONES.DEFORESTACION_MEDIO);
  }

  if (recomendaciones.length === 0) return null;
  return (
    <div style={{ padding: 12, backgroundColor: env.colors.neutral_1, borderRadius: 4, margin: 10 }}>
      <h6>Recomendaciones: </h6>
      <ul style={{ paddingInlineStart: 20, marginBottom: 0 }}>
        {recomendaciones.map((recomendacion, index) => (
          <li style={{ marginBottom: 4 }} key={index}>
            {recomendacion}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LabelRecomendaciones;
